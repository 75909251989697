.header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid rgba(134, 134, 134, 0.555);
    transition: background-color 0.5s ease;
    background-color: rgb(var(--background-start-rgb));

}
.headerFixed{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: transparent;

}

.content {
    display: flex;
    width: 100%;
    max-width: 1400px;
    align-items: center;
    justify-content: space-between;
}

.header__logo {
    cursor: pointer;
}

.header__logo:hover {
    color: #00ff00; /* Cambia el color del logo a verde al pasar el mouse */
}


@media screen and (min-width: 868px) {
    .menuMobile,
    .navMobile {
        display: none;
    }
}

@media screen and (max-width: 868px) {
    .nav {
        display: none;
    }

    .menuMobile {
        background-color: transparent;
        border: none;
    }
}
