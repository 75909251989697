

@media screen and (min-width : (868px)){
    .menuMobile{
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        color: #fff;
        font-size: 2rem;
        background-color: transparent;
        border: none;
    }
    .navMobile{
        display: none;
    }
    
}
@media screen and (max-width : (868px)){
    .closeMenuMobile
    {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        color: #fff;
        font-size: 2rem;
        background-color: transparent;
        border: none;
    }
    .navMobile__list{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0;
        li:hover{
            transform: translateY(-5px);
        }
        li{
            margin: 20px 0;
            list-style: none;
            padding: 1rem;

            
            a{
                color: #fff;
                font-size: 1.5rem;
                text-decoration: none;
            }
        }
    }
    .navMobile{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: #000000b6;
        backdrop-filter: blur(5px);
        z-index: 2;
    }

}

