@import url('https://fonts.googleapis.com/css2?family=Jacquard+12&display=swap');


.nav {
    display: flex;
}

.nav__list {
    list-style: none;
    margin: .3rem;
}

.nav__item {
    padding: .2rem;
    transition: transform 0.3s ease-in-out;
}

.nav__item a {
    color: rgb(var(--foreground-rgb)); /* Utilizza il colore del testo più scuro */
    text-decoration: none;
    padding: 10px;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    font-size: 1.3rem;
    font-weight: 400;
    font-family: "Jacquard 12", system-ui;
    border-radius: 5px;
}

.nav__item a:hover {
    color: #ff7300;
    background-color: #b6b6b641;
    border-radius: 5px;
}
.active {
    border-radius: 5px;

}
.active a{
    color: #000000;
    background-color: #ffe49b7e;
    border-radius: 5px;
    border: 1px solid #00000041;
}
.active a:hover{
    color: #000000;
    background-color: #d1cdcd4d;
    border-radius: 5px;
}

@media screen and (min-width: 868px) {
    .menuMobile,
    .navMobile {
        display: none;
    }
}

@media screen and (max-width: 868px) {
    .nav {
        display: none;
    }

    .menuMobile {
        background-color: transparent;
        border: none;
    }
}

