@import url('https://fonts.googleapis.com/css2?family=Jacquard+12&display=swap');
.logoText{
    font-family: "Jacquard 12", system-ui;
    letter-spacing: 0.2rem;
    transition: background-color 0.5s ease;
    color: rgb(var(--foreground-rgb));
    font-size: 2.5rem;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        color: #ff7300; /* Cambia el color del logo a verde al pasar el mouse */
    }
}   



.logoTextScroll{
    font-family: "Jacquard 12", system-ui;
    letter-spacing: 0.3rem;
    transition: background-color 0.5s ease;
    color: rgb(var(--foreground-rgb));
    font-size: 3.5rem;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        color: #ff7300; /* Cambia el color del logo agradable al pasar el mouse */
    }
}