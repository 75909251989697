@import url('https://fonts.googleapis.com/css2?family=Jacquard+12&display=swap');

.footer {
  background-color: rgb(var(--background-start-rgb));
  color: #000000;
    padding: 40px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(134, 134, 134, 0.555);
  }
  
  .footer__logo {
    display: flex;
    align-items: center;
  }
  
  .footer__logo h2 {
    font-family: "Jacquard 12", system-ui;
    letter-spacing: 0.3rem;
    font-size: 24px;
    cursor: pointer;
    color: rgb(var(--foreground-rgb));
  }
  
  .footer__logo__social {
    display: flex;
    margin-left: 2rem;
    gap: 20px;
    cursor: pointer;
    color: rgb(var(--foreground-rgb));
  }
  
  .footer__logo__social__item {
    overflow: hidden;
    &:hover {
      transform: scale(1.2);
      transition: all 0.3s ease;
      color: #ff7300;

    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .footer__links {
    display: flex;
    gap: 20px;
    p{
      cursor: pointer;
      font-size: 14px;
      color: rgb(var(--foreground-rgb));
    }
  }
  
  
  .footer__links__item:hover {
    text-decoration: underline;
    color: #ff7300;
  }
  

   @media screen and (max-width: 768px) {
    .footer {
      flex-direction: column;
      gap: 20px;
    }
  
    .footer__logo {
      flex-direction: column;
      gap: 20px;
    }
  
    .footer__logo h2 {
      font-size: 20px;
    }
  
    .footer__logo__social {
      margin-left: 0;
    }
  
    .footer__links {
      flex-direction: column;
      gap: 10px;
    }
  }