/* ToggleTheme.module.scss */

.toggleTheme {
    position: relative;
    display: inline-block;
    background-color: black;
    border-radius: 34px;
  }
  
  .toggle {
    display: none;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    background-color: #ccc;
    border-radius: 34px;
    cursor: pointer;
  }
  
  .switch::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 34px;
    transition: background-color 0.3s;
  }
  
  .toggle:checked + .switch::before {
    background-color: #fff783f1;
  }
  
  .iconWrapper {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 24px;
    height: 24px;
    transition: transform 0.3s;
  }
  
  .toggle:checked + .switch .iconWrapper {
    transform: translateX(26px);
  }
  