.login{
    min-height: 100dvh;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #2b282888;

}

.login_header{
    display: inline;
    padding: 20px;
    text-align: center;
    min-width: 100%;
    min-height: calc(300px - 30px);

    h1{
        margin: 0;
        font-size: 4.5rem;
        color: #ffffff;
        text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);


    }
}
.container{
    display: flex;
    width: 20%;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.644);
    backdrop-filter: blur(3px);
    min-height: 100vh;
    box-shadow: 0 0 20px rgba(6, 153, 26, 0.5);
    background-image: url('/background/712d7d8014b97625ab05473ad6cc66d58c313d44_high.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
    
    form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2rem;
        color: black;
        font-size: 1rem;
        background-color: #f1f1f1b0;
        backdrop-filter: blur(3px);
        input{
            margin: 10px 0;
            padding: 14px;
            border: none;
            border-radius: 5px;
            color: black;
            background-color: #00000088;
        }
        button{
            margin: 10px 0;
            padding: 10px;
            border: none;
            border-radius: 5px;
            background-color: #2ec510;
            color: #fff;
            cursor: pointer;
        }
    }
}
.container_image{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    a{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 5px;
        background-color: #2ec510;
        color: #fff;
        text-decoration: none;
        cursor: pointer;
    }
}
